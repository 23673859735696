import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import Link from './common/link'
import LogoV2 from '../components/logo-v2'

const Container = styled(Link)`
  display: grid;
`
const Text = styled.p`
  display: block;
  font-size: 0.75rem;
  overflow: hidden;
  :first-of-type {
    /* trim title to one line */
    color: ${({ theme }) => theme.accent};
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0.75em 0;
    letter-spacing: -0.025em;
  }
  :last-of-type {
    /* trim description to 2 lines */
    color: ${({ theme }) => theme.contrast};
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.5em;
  }
`
const Overlay = styled.div`
  position: relative;
  grid-area: 1/1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0.75rem;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.5) 30%,
    rgba(0, 0, 0, 0.5) 40%,
    rgba(0, 0, 0, 0.1) 70%,
    rgba(0, 0, 0, 0) 100%
  );
`
const Image = styled(GatsbyImage)`
  grid-area: 1/1;
  filter: saturate(45%);
  transition: filter 300ms;
  @media (hover: hover) {
    ${Container}:hover & {
      filter: saturate(100%);
    }
  }
`
const Logo = styled(LogoV2)`
  width: 15%;
  opacity: 0.75;
`
const Thumbnail = ({ id, image, title, description }) => {
  return (
    <Container to={id}>
      <Image image={image} alt={title} />
      <Overlay>
        <Logo />
      </Overlay>
      <Text>{title}</Text>
      <Text>{description}</Text>
    </Container>
  )
}

export default Thumbnail
